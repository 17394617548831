import React from "react";
import {observer} from "mobx-react";
import history from "../helper/browserHistory";
import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {Link} from "react-router-dom";

@withTranslation()
@observer
export default class FooterComponent extends React.Component {

    render() {
        const {i18n} = this.props;
        let copyright = "©" + (new Date().getFullYear()) + " Lock Therapie";
        return (
            <Grid padded style={{padding: "0px"}}>
                <Grid.Row width={16} className={"footer-container"} style={{paddingBottom: "40px"}}>
                    <Grid.Column width={1} only={"computer"}/>
                    <Grid.Column computer={2} tablet={8} mobile={8} style={{paddingBottom: "40px"}}>
                        <p style={{fontWeight: 500, lineHeight: "180%"}}>
                            <b>{i18n.t("footer.company")}</b>
                            <br/>
                            {i18n.t("footer.name")}
                            <br/>
                            {i18n.t("footer.street")}
                            <br/>
                            {i18n.t("footer.city")}

                        </p>
                        <p onClick={() => history.push("/login")}
                           className={"footer-link"}>
                            {i18n.t('titles.login')}
                        </p>
                        {/* <Image
                            src={"http://localhost:3000/images/logo.png"}/> */}
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={8} mobile={8}>
                        <p style={{fontWeight: 500, lineHeight: "180%"}}>
                            <b>{i18n.t("phone.number")}</b>
                            <br/>
                            {i18n.t("footer.mail")}
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={1} tablet={8} mobile={8}>
                        <p onClick={() => history.push("/")} style={{paddingLeft: "0px"}} className={"footer-link"}>
                            <b>{i18n.t('titles.home')}</b>
                        </p>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={8} mobile={8} style={{paddingBottom: "40px"}}>
                        <p>
                            <b>{i18n.t('titles.about_us')}</b>
                        </p>

                        <Link to={"/philosophy"} className={"footer-link"}>
                            {i18n.t('titles.philosophy')}
                        </Link>
                        <br/>
                        <Link to={"/team"} className={"footer-link"}>
                            {i18n.t('titles.team')}
                        </Link>
                        {/* <p  onClick={() => history.push("/history")}  className={"footer-link"}>
                            { i18n.t('titles.history') }
                        </p> */}
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={8} mobile={8}>
                        <p>
                            <b>{i18n.t('titles.services')}</b>
                        </p>
                        <Link to={"/physiotherapy"} className={"footer-link"}>
                            {i18n.t('titles.physiotherapy')}
                        </Link>
                        <br/>
                        <Link to={"/natural_healing"} className={"footer-link"}>
                            {i18n.t('titles.natural_healing')}
                        </Link>
                        <br/>
                        {/*  <p  onClick={() => history.push("/homeopathy")}  className={"footer-link"}>
                            { i18n.t('titles.homeopathy') }
                        </p> */}
                        <Link to={"/therapy_tool"} className={"footer-link"}>
                            {i18n.t('titles.therapy_tool')}
                        </Link>
                        <br/>
                        <Link to={"/price"} className={"footer-link"}>
                            {i18n.t('titles.prices')}
                        </Link>
                    </Grid.Column>{/*  floated='right' */}
                    <Grid.Column computer={2} tablet={8} mobile={8}>
                        <Link to={"/data-privacy"}
                              className={"footer-link"}>
                            <b>{i18n.t('titles.data_privacy')}</b>
                        </Link>

                        <br/>
                        <Link to={"/imprint"}
                              className={"footer-link"}>
                            <b>{i18n.t('titles.imprint')}</b>
                        </Link>
                    </Grid.Column>
                    <Grid.Column width={1} only={"computer"}/>
                </Grid.Row>
                <Grid.Row className={"footer-container"}>
                    <Grid.Column style={{textAlign: "center"}}>
                        <p>{copyright}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}