import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import ToolsCarousel from "../components/ToolsCarousel";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import BreadCrumbs from "../components/BreadCrumbs";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
//import TherapyToolsCards from "../components/TherapyToolsCards";


@withTranslation()
@inject("stores")
@observer
export default class TherapyToolsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            filterString: ''
        }
    }

    componentWillMount() {

    }

    filterStringChanged(e) {
        this.setState({filterString: e.target.value});
    }

    render() {
        // <EditImageComponent id={"galery_tools"}/>
        const {i18n} = this.props;
        return (
            <div>
                <EditHeaderComponent id="header_therapy_tools"/>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.therapy_tool'), url: '/therapy_tool'}]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}
                                     style={{padding: "0px"}}>
                            <div className={"div-ratio-one-two-container"}>
                                <div className={"div-ratio-content"}>
                                    <Image
                                        alt={"item"}
                                        src={config.PUBLIC_URL + "images/galery_therapeuthische_geraete.jpg"}/>
                                </div>
                            </div>
                            <div className={"image_category_text_primary"}>
                                {/* i18n.t("titles.services") */}
                                {i18n.t("titles.therapy_tool")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered style={{paddingTop: "0px"}}>
                        <Grid.Column width={14}>
                            <ToolsCarousel/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Image
                                alt={"item"}
                                style={{width: "170px", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/welle.svg"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}