import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import {observable} from "mobx";
import userStore from "../stores/userStore"

import config from "../../config/main.config";
import {getTokenFromLocalStorage} from "../helper/util";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

@observer
export default class EditImageComponent extends React.Component {

    @observable selectImages = [];
    @observable selectModalOpen = false;

    @observable editingAlt = false;
    @observable altText = "";

  /*  @observable
    image = {
        link: "",
        alt: ""
    };*/

    constructor() {
        super();
        this.state = { image : {
            link: "",
            alt: ""
        }}
    }

    componentDidMount() {
        this.fetchFrontendIdImage();
        this.fetchAllImages();
        //console.log("Constructor: " + this.props.id);
    }

    fetchAllImages() {
        fetch(config.BASE_URL + "images/web", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    let allImages = [];
                    for (let i = 0; i < json.length; i++) {
                        allImages.push({
                            link: json[i]
                        })
                    }
                    this.selectImages = allImages;
                   // console.log("Fetch all")
                    //console.log(allImages);
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
               // console.log(error);

                if (error.status === 401) {
                    history.replace("/admin-login");
                }
            }
        );
    }

    fetchFrontendIdImage() {
        let frontendId = this.props.id;
       // console.log("fetchFrontendIdImage: " + frontendId);

        fetch(config.BASE_URL + "images/single/" + frontendId, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    let link = '';
                    let alt = '';
                    if (json.hasOwnProperty("link")) {
                        link = json.link;
                    }

                    if (json.hasOwnProperty("alt")) {
                        alt = json.alt;
                    }
                    this.setState({ image: { alt: alt, link:link }});
                    this.editingAlt = false;
                });

            } else {
                console.log(response.status)
            }
        }).catch(
            error => {
                console.log(error);
                if (error.status === 401) {
                    history.replace("/admin-login");
                }

            }
        );
    }

    deleteImage() {
        fetch(config.BASE_URL + "images/single/", {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({frontendId: this.props.id})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.fetchFrontendIdImage()
            } else {
                console.log(response.status)
                //TODO: Alert?
            }
        }).catch(
            error => {
                console.log(error);
            }
        );
    }

    uploadEditImage(event) {
        let self = this;
        const data = new FormData();
        let files = Array.from(event.target.files);
        let frontendId = this.props.id;
        console.log("Frontend ID");
        console.log(frontendId);

        //Send files
        if (files.length > 0) {
            data.append("image", files[0]);
        } else {
            return;
        }
        fetch(config.BASE_URL + 'images/upload-single-image/' + frontendId, {
            method: "POST",
            body: data,
            headers: {
                "baseurl": window.location.host,
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.fetchFrontendIdImage()
            }
        }).catch(() => {
        });

    }

    selectImage(link) {
        let self = this;

        //put links to component
        fetch(config.BASE_URL + 'images/single-selection/', {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({frontendId: this.props.id, link: link})
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.componentDidMount();
                self.selectModalOpen = false;
            }
        }).catch(() => {
        });

    }

    changeText(event) {
        this.altText = event.target.value;
        this.setState({});
    }

    editImageAlt() {
        let self = this;

        let body = {
            frontendId: this.props.id,
            link: this.state.image.link,
            alt: this.altText
        };

        fetch(config.BASE_URL + 'images/alt', {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                self.fetchFrontendIdImage()
            }
        }).catch(() => {
        });
    }
/* style={{width: "100%"}} */
    render() {
        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let contentToShow = '';
        if (this.state.link !== '') {
            contentToShow =
                <LazyLoadImage
                    alt={this.state.image.alt}
                    style={this.props.style}
                    effect="opacity"
                    src={this.state.image.link}/>;
        }

        if (displayType === "admin") {
            let showSelectImages = this.selectImages.map((item, index) =>
                <Grid.Column key={index} width={5}>
                    <Image
                        alt={"item"}
                        src={item.link}
                        fluid
                        onClick={() => this.selectImage(item.link)}
                    />
                </Grid.Column>
            );
            contentToShow = <Grid>
                <Grid.Row>
                    <Grid.Column>
                        {this.state.image.link.length > 0 ?
                            <Button
                                negative
                                onClick={() => this.deleteImage()}
                                attached={"top"}
                                className={"gallery-delete-button"}>
                                Löschen
                            </Button>
                            :
                            null
                        }
                        <Image
                            alt={"item"}
                            src={this.state.image.link}
                            fluid
                        />
                        {this.editingAlt ?
                            <Form>
                                <Form.Input
                                    onChange={this.changeText.bind(this)}
                                    value={this.altText}
                                />
                                <Form.Field>
                                    <Button.Group>
                                        <Button
                                            positive
                                            onClick={() => this.editImageAlt()}>
                                            Bearbeiten
                                        </Button>
                                        <Button
                                            negative
                                            onClick={() => {
                                                this.altText = this.state.image.alt;
                                                this.editingAlt = false;
                                            }}>
                                            Abbrechen
                                        </Button>
                                    </Button.Group>
                                </Form.Field>
                            </Form>
                            :
                            <div>
                                <p>{this.state.image.alt}</p>
                                <Button
                                    positive
                                    onClick={() => {
                                        this.altText = this.state.image.alt;
                                        this.editingAlt = true;
                                    }}>
                                    alt-tag bearbeiten
                                </Button>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <input type="file" id="file" className="inputfile"
                               onChange={this.uploadEditImage.bind(this)}
                               accept="image/*"
                               multiple/>
                        <Button.Group>
                            <Button
                                color={"orange"}
                                onClick={() => this.selectModalOpen = true}>
                                Auswählen
                            </Button>
                            <Button color={"teal"}>
                                <label htmlFor="file">
                                    Hochladen
                                </label>
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
                <Modal open={this.selectModalOpen}
                       style={{"margin": "auto"}}
                       onClose={() => this.selectModalOpen = false} centered={true}>
                    <Modal.Header>{"Auswählen"}</Modal.Header>
                    <Modal.Content scrolling>
                        <Grid>
                            <Grid.Row>
                                {showSelectImages}
                            </Grid.Row>
                        </Grid>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative icon='delete' onClick={() => this.selectModalOpen = false}/>
                    </Modal.Actions>
                </Modal>
            </Grid>;
        }
        return (contentToShow);
    }
}

EditImageComponent.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object
};

EditImageComponent.defaultProps = {
    id: Math.random().toString(),
    style: { width: "100%" }
};