import React from "react"
import {inject, observer} from "mobx-react";

import NavigationBar from "../components/NavigationBar"
import textStore from "../stores/textStore"
import {renderRoutes} from "react-router-config";
import allRoutes from "../../../shared/routes";
import CookieConsent from "react-cookie-consent";
import FooterComponent from "../components/FooterComponent";
import {withTranslation} from "react-i18next";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import {Helmet} from "react-helmet";
import SideBarContact from "../components/SideBarContact";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";


if (typeof document !== "undefined") {
    // Require scss files
    require('../../stylesheets/_all.scss');
}

@inject("stores")
@withTranslation()
@observer
export default class Layout extends React.Component {

    render() {
        const {i18n} = this.props;
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        return (textStore.loading ?
                <Dimmer active inverted>
                    <Helmet
                        htmlAttributes={{"lang": language}}
                    />
                    <Loader inverted content={i18n.t('page.loading')}/>
                </Dimmer>
                :
                <div>
                    <SideBarContact/>
                    <Grid centered columns={2}>
                        <Helmet
                            htmlAttributes={{"lang": language}}
                        />
                        <CookieConsent
                            location="bottom"
                            buttonText={i18n.t('cookie_consent.button')}
                            cookieName="cookieName"
                            style={{background: "#2B373B", fontSize: "20px"}}
                            buttonStyle={{color: "#4e503b", fontSize: "13px"}}>
                            {i18n.t('cookie_consent.text')}
                        </CookieConsent>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <NavigationBar/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Responsive as={Grid.Row} maxWidth={1030}>
                        <Grid style={{"width": "100%", "margin": "auto"}}>
                            <Grid.Row centered columns={1} className={"default-width-pc"}
                                      style={{"width": "80%", "margin": "auto"}}>
                                <Grid.Column style={{"padding": "0px", "minHeight": "70vh"}}>
                                    {renderRoutes(allRoutes)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Responsive>
                    <Responsive as={Grid.Row} minWidth={1031}>
                        <Grid style={{"width": "100%", "margin": "auto", paddingRight: "0px"}}>
                            <Grid.Row centered columns={1}>
                                <Grid.Column style={{
                                    paddingBottom: "40px",
                                    paddingTop: "50px",
                                    paddingRight: "0px",
                                    "minHeight": "70vh"
                                }}>
                                    {renderRoutes(allRoutes)}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Responsive>
                    <Grid>
                        <Grid.Row style={{"paddingBottom": "0px"}}>
                            <Grid.Column width={16}>
                                <FooterComponent/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
        );
    }
}