import React from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";
import {withTranslation} from "react-i18next";
import sharedConfig from "../../../shared/sharedConfig";
import userStore from "../stores/userStore"
import {getEditStyle} from "./util";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";

@inject("stores")
@withTranslation()
@observer
export default class EditTextComponent extends React.Component {
    @observable editState = false;

    @observable content = "";
    @observable language = 'de-DE';

    handleChange = (e, {value}) => this.language = value;

    constructor(props) {
        super(props);
        this.changeText = this.changeText.bind(this);
    }

    changeText(event) {
        const name = event.target.name;
        this[name] = event.target.value;
    }


    render() {
        const {textStore} = this.props.stores;
        // const { newData } = textStore.texts;

        let {id, style} = this.props;

        if (typeof textStore.texts[id] !== "undefined") {
            if (typeof textStore.texts[id][this.language] !== "undefined") {
                textToShow = textStore.texts[id][this.language];
                console.log("############# textToShow");
                console.log(textToShow);
            } else {
                textToShow = {content: ""};
            }
        }


        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let textToShow = {content: ""};
        let contentToShow = "";

        if (displayType === "admin" && typeof document !== "undefined") {
            style = getEditStyle(style);

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][this.language] !== "undefined") {
                    textToShow = textStore.texts[id][this.language];
                } else {
                    textToShow = {content: ""};
                }
            }

            if (this.editState) {
                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <label>{this.props.header}</label>
                            <TextArea style={{height: "150px", width: "100%"}}
                                      name='content'
                                      onChange={this.changeText}
                                      value={this.content}/>
                        </Form.Field>
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {

                                    let textToEdit = {
                                        content: this.content,
                                        title: '',
                                        description: ''
                                    };

                                    if (typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.language] === "undefined") {
                                        textStore.createText(id, textToEdit, this.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    } else {
                                        textStore.updateText(id, textToEdit, this.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    }
                                }}>
                                    {typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.language] === "undefined" ? "Speichern" : "Bearbeiten"}
                                </Button>
                                <Button color="red" onClick={() => this.editState = false}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>
                </div>
            } else {
                const languageRadioButtons = sharedConfig.LANGUAGES.map(language => (
                    <Form.Field key={language.language}>
                        <Radio
                            label={language.label}
                            name='radioGroup'
                            value={language.language}
                            checked={this.language === language.language}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                ));

                contentToShow = <div style={style}>
                    <Form>
                        {languageRadioButtons}
                    </Form>
                    <div id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.content = toJS(textToShow).content;
                        this.editState = true
                    }}/>
                </div>
            }

        } else {
            let language = "de-DE";

            if (this.props.i18n) {
                language = this.props.i18n.language;
            }

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][language] !== "undefined") {
                    textToShow = toJS(textStore.texts[id][language]);
                } else {
                    textToShow = {};
                }
            }
            let text = "";

            if (typeof textToShow.content !== "undefined") {
                text = textToShow.content.replace(/\n/g, "<br/>");
            }

            contentToShow = <div>
                <div style={style} id={id} dangerouslySetInnerHTML={{__html: text}}/>
            </div>;
        }
        return (
            contentToShow
        );
    }
}

EditTextComponent.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    header: PropTypes.string,
};

EditTextComponent.defaultProps = {
    id: Math.random().toString(),
    style: null,
    header: "Wert"
};

