import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import EditImageComponent from "../moap-components/EditImageComponent";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import BreadCrumbs from "../components/BreadCrumbs";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
//import TherapyToolsCards from "../components/TherapyToolsCards";


@withTranslation()
@inject("stores")
@observer
export default class PhysiotherapyPage extends React.Component {
    constructor() {
        super();
    }

    render() {
        const {i18n} = this.props;
        // <EditImageComponent id={"galery_physiotherapy"} />
        return (
            <div>
                <EditHeaderComponent id="header_physiotherapie"/>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.physiotherapy'), url: '/physiotherapy'}]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}
                                     style={{padding: "0px"}}>
                            <div className={"div-ratio-one-two-container"}>
                                <div className={"div-ratio-content"}>
                                    <Image
                                        alt={"item"}
                                        src={config.PUBLIC_URL + "images/galery_physiotherapie.jpg"}/>
                                </div>
                            </div>
                            <div className={"image_category_text_primary"}>
                                {/* i18n.t("titles.services") */}
                                {i18n.t("titles.physiotherapy")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: "50px", paddingTop: "0px"}} centered columns={1}>
                        <Grid.Column computer={12} tablet={12} mobile={14} style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "40px",
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderColor: "#C0C0C0",
                            margin: "auto"
                        }}>
                            <Image
                                alt={"item"}
                                className={"image_wave_icon"}
                                src={config.PUBLIC_URL + "images/welleklein.jpg"}/>
                            <EditHTMLComponent id="html_physiotherapy"/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Grid>
                    <Grid.Row columns={2} centered style={{padding: "40px", backgroundColor: "#EDEDED"}}>
                        <Grid.Column computer={6} tablet={15} mobile={15}>
                            <EditImageComponent id="img_physiotherapy" style={{height: "auto", width: "100%"}}/>
                        </Grid.Column>
                        <Grid.Column computer={6} tablet={15} mobile={15}>
                            <div style={{paddingTop: "20px", width: "100%"}}>
                                <EditHTMLComponent id="html_physiotherapy_services"/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <Image
                                alt={"item"}
                                style={{width: "240px", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/welle.svg"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}