import React from "react";
import {observer} from "mobx-react";
import {removeTokenFromStorage} from "../helper/util";
import userStore from "../stores/userStore";
import {observable} from "mobx";
import history from "../helper/browserHistory";
import config from "../../config/main.config";

import {withTranslation} from "react-i18next";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {Link} from "react-router-dom";

@withTranslation()
@observer
export default class NavigationBar extends React.Component {
    @observable
    showMenu = false;

    @observable
    activeElement = "none";

    setActive() {
        if (typeof window !== "undefined") {
            switch (window.location.pathname) {
                case "/":
                    this.activeElement = "/";
                    break;
                case "/philosophy":
                    this.activeElement = "philosophy";
                    break;
                case "/team":
                    this.activeElement = "team";
                    break;
                case "/history":
                    this.activeElement = "history";
                    break;
                case "/physiotherapy":
                    this.activeElement = "physiotherapy";
                    break;
                case "/therapy_center":
                    this.activeElement = "therapy_center";
                    break;
                case "/homeopathy":
                    this.activeElement = "homeopathy";
                    break;
                case "/therapy_tool":
                    this.activeElement = "therapy_tool";
                    break;
                case "/price":
                    this.activeElement = "price";
                    break;
                case "/user-management":
                    this.activeElement = "user-management";
                    break;
                case "/imprint":
                    this.activeElement = "imprint";
                    break;
                case "/data-privacy":
                    this.activeElement = "data-privacy";
                    break;
                case "/login":
                    this.activeElement = "login";
                    break;
                default:
                    this.activeElement = "none";
                    break;
            }
        }
        this.setState({});
    }

    componentDidMount() {
        this.setActive();
        history.listen(this.setActive.bind(this));
    }

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        this.activeMobileAccordionIndex = this.activeMobileAccordionIndex === index ? -1 : index;
        this.setState({})
    };


    render() {
        const {i18n} = this.props;
        let mobileFontSize = "17px";
        return (
            <nav className={"ui grid"}>
                <Responsive as={Grid.Row} maxWidth={787} className={"mobile-burger-row"}>
                    <Grid.Column width={16}>
                        <div key="hamburger" className="hamburger-mobile-navigation">
                            <div className="hamburger" onClick={() => this.showMenu = !this.showMenu}>
                                <div className={"bar1 " + (this.showMenu ? "change" : "")}/>
                                <div className={"bar2 " + (this.showMenu ? "change" : "")}/>
                                <div className={"bar3 " + (this.showMenu ? "change" : "")}/>
                            </div>
                        </div>
                    </Grid.Column>
                </Responsive>
                <Responsive as={Grid.Row} maxWidth={787} className={this.showMenu ? "no-top-padding" : "hide-div"}>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Segment className={"navigation-menu-segment"} style={{"borderRadius": "0px"}}>
                            <Menu key="menu" pointing secondary stackable className={"navigation-menu"}
                                  widths={4}>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "/"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="home">
                                    <Link to={"/"}>
                                        {i18n.t('titles.home')}
                                    </Link>
                                </Menu.Item>
                                <p style={{paddingLeft: "10px", paddingTop: "0px", textAlign: "left"}}>
                                    <b>{i18n.t("titles.about_us")}</b></p>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "philosophy"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="philosophy">
                                    <Link to={"/philosophy"}>
                                        {i18n.t('titles.philosophy')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "team"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="team">
                                    <Link to={"/team"}>
                                        {i18n.t('titles.team')}
                                    </Link>
                                </Menu.Item>
                                {/*  <Menu.Item
                                    style={{ fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "history"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="history">
                                    <Link to={"/history"}>
                                        {i18n.t('titles.history')}
                                    </Link>
                                </Menu.Item> */}
                                <p style={{paddingLeft: "10px", paddingTop: "0px", textAlign: "left"}}>
                                    <b>{i18n.t("titles.services")}</b></p>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "physiotherapy"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="physiotherapy">
                                    <Link to={"/physiotherapy"}>
                                        {i18n.t('titles.physiotherapy')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "natural_healing"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="natural_healing">
                                    <Link to={"/natural_healing"}>
                                        {i18n.t('titles.natural_healing')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "therapy_tool"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="therapy_tool">
                                    <Link to={"/therapy_tool"}>
                                        {i18n.t('titles.therapy_tool')}
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    style={{fontSize: "18px", paddingBottom: "12px", paddingTop: "12px", border: "0px"}}
                                    active={this.activeElement === "price"}
                                    onClick={() => {
                                        this.showMenu = false;
                                        this.setState({});
                                    }}
                                    key="prices">
                                    <Link to={"/price"}>
                                        {i18n.t('titles.prices')}
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column comuter={1} only={'computer'}/>
                </Responsive>

                {/* ######################################################## */}

                <Responsive as={Grid.Row} minWidth={788} className={"nav-row"}>
                    <Grid.Column width={5} only={"computer"}>
                        <Image
                            alt={"item"}
                            className={"nav-bar-image"}
                            onClick={() => {
                                history.push("/");
                                this.setState({});
                            }}
                            alt={"Lock Therapie Logo"}
                            src={config.PUBLIC_URL +  "images/logo_therapie.svg"}/>
                    </Grid.Column>
                    <Grid.Column width={11} style={{paddingRight: "0", paddingTop: "30px"}} floated='right'
                                 textAlign={"right"}>
                        <Menu key="menu" pointing secondary stackable floated="right" className={"navigation-menu"}>
                            <Menu.Item
                                style={{height: "0px", color: 'black'}}
                                active={this.activeElement === "/"}
                                onClick={() => {
                                    this.showMenu = false;
                                    this.setState({});
                                }}
                                key="home">
                                <Link to={"/"} style={{color: 'black'}}>
                                    {i18n.t('titles.home')}
                                </Link>
                            </Menu.Item>
                            <Dropdown pointing={"top right"} item text={i18n.t("titles.about_us")}
                                      style={this.activeElement === "philosophy" || this.activeElement === "team" || this.activeElement === "history" ? {
                                          height: "20px",
                                          borderBottom: "3px solid #0000AA"
                                      } : {height: "0px"}}>
                                <Dropdown.Menu>
                                    <Dropdown.Item style={{padding: "0px"}}>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "philosophy"}
                                            onClick={() => {
                                                history.push("/philosophy");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="philosophy">
                                            {i18n.t('titles.philosophy')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "team"}
                                            onClick={() => {
                                                history.push("/team");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="team">
                                            {i18n.t('titles.team')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item>
                                            <Menu.Item
                                                style={{fontSize: "20px", color: "#000000", paddingBottom: "6px", textAlign: "center"}}
                                                active={this.activeElement === "history"}
                                                onClick={() => {
                                                    history.push("/history");
                                                    this.showMenu = false;
                                                    this.setState({});
                                                }}
                                                key="history">
                                                {i18n.t('titles.history')}
                                            </Menu.Item>
                                        </Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown pointing={"top right"} item text={i18n.t("titles.services")}
                                      style={this.activeElement === "physiotherapy" || this.activeElement === "therapy_center" || this.activeElement === "price" || this.activeElement === "therapy_tool" ? {
                                          borderBottom: "3px solid #0000AA",
                                          height: "0px"
                                      } : {height: "0px"}}>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "physiotherapy"}
                                            onClick={() => {
                                                history.push("/physiotherapy");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="physiotherapy">
                                            {i18n.t('titles.physiotherapy')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "natural_healing"}
                                            onClick={() => {
                                                history.push("/natural_healing");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="therapy_center">
                                            {i18n.t('titles.natural_healing')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                    {/*}    <Dropdown.Item>
                                            <Menu.Item
                                                style={{fontSize: "20px", color: "#000000", paddingBottom: "6px", textAlign: "center"}}
                                                active={this.activeElement === "homeopathy"}
                                                onClick={() => {
                                                    history.push("/homeopathy");
                                                    this.showMenu = false;
                                                    this.setState({});
                                                }}
                                                key="homeopathy">
                                                {i18n.t('titles.homeopathy')}
                                            </Menu.Item>
                                        </Dropdown.Item> */}
                                    <Dropdown.Item>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "therapy_tool"}
                                            onClick={() => {
                                                history.push("/therapy_tool");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="therapy_tool">
                                            {i18n.t('titles.therapy_tool')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <Menu.Item
                                            style={{
                                                fontSize: "20px",
                                                color: "#000000",
                                                paddingBottom: "6px",
                                                textAlign: "center"
                                            }}
                                            active={this.activeElement === "price"}
                                            onClick={() => {
                                                history.push("/price");
                                                this.showMenu = false;
                                                this.setState({});
                                            }}
                                            key="price">
                                            {i18n.t('titles.prices')}
                                        </Menu.Item>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {
                                userStore.userFromServer !== null ?
                                    <Menu.Item
                                        style={{height: "0px", color: "#000000"}}
                                        active={this.activeElement === "user-management"}
                                        onClick={() => {
                                            history.push("/user-management");
                                            this.showMenu = false;
                                            this.setState({})
                                        }} key="user-management">
                                        {i18n.t('titles.user_management')}
                                    </Menu.Item> : null
                            }
                            {
                                userStore.userFromServer !== null ?
                                    <Menu.Item
                                        style={{height: "0px", color: "#000000"}}
                                        active={this.activeElement === "blog-overview"}
                                        onClick={() => {
                                            history.push("/blog-overview");
                                            this.showMenu = false;
                                            this.setState({});
                                        }}
                                        key="blog">
                                        {i18n.t('titles.blog_overview')}
                                    </Menu.Item> : null
                            }
                            {
                                userStore.userFromServer !== null ?
                                    <Menu.Item
                                        style={{height: "0px", color: "#000000"}}
                                        position='right'
                                        onClick={() => {
                                            removeTokenFromStorage();
                                            userStore.user = null;
                                            this.setState({})
                                        }}
                                        active={true}
                                        key="logout">
                                        <Link to={"/"}>
                                            {i18n.t('titles.logout')}
                                        </Link>
                                    </Menu.Item>
                                    :
                                    null
                            }
                        </Menu>
                    </Grid.Column>
                </Responsive>
            </nav>

        );
    }
}