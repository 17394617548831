import ImprintPage from "../react/js/pages/ImprintPage";
import DataPrivacyPage from "../react/js/pages/DataPrivacyPage";
import RegisterPage from "../react/js/pages/RegisterPage";
import HomePage from "../react/js/pages/HomePage"
import PricePage from "../react/js/pages/PricePage"
import TherapyToolsPage from "../react/js/pages/TherapyToolsPage"
import PhilosophyPage from "../react/js/pages/PhilosophyPage";
import TeamPage from "../react/js/pages/TeamPage";
import PhysiotherapyPage from "../react/js/pages/PhysiotherapyPage";
import NaturalHealPage from "../react/js/pages/NaturalHealPage";
import Loadable from 'react-loadable';
import LoaderComponent from "../react/js/components/LoaderComponent";
import NotFoundPage from "../react/js/pages/NotFoundPage";

//import Login from "../react/js/pages/Login";
const LoginPage = Loadable({
    loader: () => import(/* webpackChunkName: 'LoginPage' */ "../react/js/pages/LoginPage"),
    loading: () => LoaderComponent
});

const BlogArticlePage = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogArticlePage' */ "../react/js/pages/Blog/BlogArticlePage"),
    loading: () => LoaderComponent
});

const BlogAuthorEdit = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogAuthorEdit' */ "../react/js/pages/Blog/BlogAuthorEdit"),
    loading: () => LoaderComponent
});

const BlogOverviewPage = Loadable({
    loader: () => import(/* webpackChunkName: 'BlogOverviewPage' */ "../react/js/pages/Blog/BlogOverview"),
    loading: () => LoaderComponent
});

const UserManagementPage = Loadable({
    loader: () => import(/* webpackChunkName: 'UserManagementPage' */ "../react/js/pages/User/UserManagementPage"),
    loading: () => LoaderComponent
});

const sharedRoutes = [
    {
        path: "/",
        exact: true,
        component: HomePage
    },
    {
        path: "/404",
        exact: true,
        component: NotFoundPage
    },
    {
        path: "/philosophy",
        exact: true,
        component: PhilosophyPage
    },
    {
        path: "/team",
        exact: true,
        component: TeamPage
    },
    {
        path: "/physiotherapy",
        exact: true,
        component: PhysiotherapyPage
    },
    {
        path: "/natural_healing",
        exact: true,
        component: NaturalHealPage
    },
    {
        path: "/therapy_tool",
        exact: true,
        component: TherapyToolsPage
    },
    {
        path: "/price",
        exact: true,
        component: PricePage
    },
    {
        path: "/blog-overview",
        exact: true,
        component: BlogOverviewPage
    },
    {
        path: "/blog-author-edit/:id",
        exact: true,
        component: BlogAuthorEdit
    },
    // Start: User Management Routes
    {
        path: "/user-management",
        exact: true,
        component: UserManagementPage
    },
    // Start: User Management Routes
    {
        path: "/imprint",
        exact: true,
        component: ImprintPage
    }, {
        path: "/data-privacy",
        exact: true,
        component: DataPrivacyPage
    }, {
        path: "/login",
        exact: true,
        component: LoginPage
    }, {
        path: "/register",
        exact: true,
        component: RegisterPage
    }
];

export default sharedRoutes;