import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import NewsCarousel from "../components/NewsCarousel";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Vimeo from '@u-wave/react-vimeo';
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Responsive from "semantic-ui-react/dist/commonjs/addons/Responsive";
import BreadCrumbs from "../components/BreadCrumbs";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

@withTranslation()
@inject("stores")
@observer
export default class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterString: '',
            activeIndex: -1
        }
    }

    render() {
        const {i18n} = this.props;
        return (
            <div>
                <EditHeaderComponent id="header_home"/>
                <BreadCrumbs breadCrumbData={[]}/>
                <Grid className={"home-top-banner"}>
                    <Responsive as={Grid.Row} minWidth={767}
                                style={{padding: "40px", backgroundColor: "#EDEDED"}}>

                        {/* <Grid.Row columns = {2}  style={{padding: "40px", margin: "0px",  backgroundColor: "#EDEDED" }}  > */}
                        <Grid.Column computer={8} tablet={8} style={{backgroundColor: "white"}}>
                            <Vimeo
                                video="485852788"
                                muted
                                autoplay
                                loop
                                responsive
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    marginLeft: "10px"
                                }}
                            />
                            {/*<iframe src="https://player.vimeo.com/video/485852788?autoplay=1&loop=1" width={"100%"}
                                    height={"100%"}
                                    frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/> */}
                        </Grid.Column>
                        <Grid.Column style={{backgroundColor: "white"}} computer={8} tablet={8}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Image
                                            alt={"item"}
                                            style={{width: "80%", height: "auto", paddingTop: "30px", margin: "auto"}}
                                            src={config.PUBLIC_URL + "images/logo_therapie.svg"}/>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row centered style={{paddingTop: "30px", paddingBottom: "50px"}}>
                                    <Grid.Column width={3}/>
                                    <Grid.Column width={2}
                                                 style={{marginTop: "6px", paddingLeft: "20px", paddingRight: "0px"}}>
                                        <Image
                                            alt={"mark"}
                                            src={config.BASE_URL_IMAGES + "mark.png"}
                                            style={{height: "40px", marginRight: "0px", width: "auto"}}/>
                                    </Grid.Column>
                                    <Grid.Column width={7} style={{margin: "auto", paddingLeft: "0"}}>
                                        <div style={{fontSize: "22px"}}>
                                            {i18n.t("home.quotation")}
                                            <span style={{fontSize: "16px"}}> {i18n.t("home.author")} </span>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={4}/>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Responsive>
                    <Responsive as={Grid.Row} maxWidth={766}>
                        <Grid.Column width={16}
                                     style={{padding: "0px"}}>
                            <Image
                                alt={"item"}
                                fluid
                                style={{height: "auto", width: "100%"}}
                                src={config.PUBLIC_URL + "images/spa_mobile.jpg"}/>
                        </Grid.Column>
                    </Responsive>
                    <Responsive as={Grid.Row} maxWidth={766}>
                        <Grid.Column>
                            <Image
                                alt={"logo"}
                                style={{width: "60%", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/logobig.png"}/>
                        </Grid.Column>
                    </Responsive>
                    <Grid.Row only={"mobile"} columns={1}>
                        <Grid.Column width={13} style={{margin: "auto", paddingLeft: "0"}}>
                            <div style={{fontSize: "22px"}}>
                                {i18n.t("home.quotation")}
                                <span style={{fontSize: "16px"}}>  {i18n.t("home.author")} </span>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: "50px"}}/>
                    <Grid.Row style={{paddingBottom: "50px"}} centered columns={1}>
                        <Grid.Column computer={12} tablet={12} mobile={14} style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "40px",
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderColor: "#C0C0C0",
                            margin: "auto"
                        }}>
                            <Image
                                alt={"item"}
                                className={"image_wave_icon"}
                                src={config.PUBLIC_URL + "images/welleklein.jpg"}/>
                            <EditHTMLComponent id="homepageClaim_Text"/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: "0"}}/>
                </Grid>
                <NewsCarousel/>
                <Grid>
                    <Grid.Row>
                        <Image
                            alt={"item"}
                            style={{width: "250px", paddingTop: "30px", margin: "auto", height: "auto"}}
                            src={config.PUBLIC_URL + "images/welle.svg"}/>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}