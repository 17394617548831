import 'babel-polyfill';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import 'whatwg-fetch';
import React from "react"
import ReactDOM from "react-dom"
import history from "./helper/browserHistory"
import {Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {HelmetProvider} from 'react-helmet-async';
import initStores from "./stores/initStores";

import Layout from "./pages/Layout"
import './i18n';

const app = document.getElementById('app');

const stores = initStores(window.STORES);

// Fetch texts
if(stores.textStore) {
    stores.textStore.fetchTexts();
}

ReactDOM.render(
    <HelmetProvider>
        <Provider stores={stores}>
            <Router history={history}>
                <Layout/>
            </Router>
        </Provider>
    </HelmetProvider>,
    app
)
;