/**
 * triple-A Soft, 2020
 */

import { isDevelop } from "../js/helper/util";
/*
    URL: isDevelop() ? "http://localhost:3003/" : "https://www.locktherapie.de/",
    BASE_URL: isDevelop() ? "http://localhost:3003/api/" : "https://www.locktherapie.de/api/",
    BASE_URL_IMAGES : isDevelop() ? "http://localhost:3003/images/" : "https://www.locktherapie.de/images/",
    BASE_URL_BLOG_IMAGES : isDevelop() ? "http://localhost:3003/images/blog-images/" : "https://www.locktherapie.de/images/blog-images/",
    PUBLIC_URL: isDevelop() ? "http://localhost:3003/" : "https://www.locktherapie.de/",
    PUBLIC_DATA_LINK: isDevelop() ? "http://localhost:8080/data-privacy" : "https://www.locktherapie.de/data-privacy",
    LINK_LOCK_DAY_SPA: "http://lockdayspa.de/",
    STORAGE_KEY: "4WfG5jv3",
    USER_STORAGE_KEY: "cjhSopcv0w9",
    FAQ_CATEGORIES: ["News", "Buchen", "Digitalisierung"]
 */

export default {
    URL: isDevelop() ? "http://localhost:3003/" : "https://www.locktherapie.de/",
    BASE_URL: isDevelop() ? "http://localhost:3003/api/" : "https://www.locktherapie.de/api/",
    BASE_URL_IMAGES : isDevelop() ? "http://localhost:3003/images/" : "https://www.locktherapie.de/images/",
    BASE_URL_BLOG_IMAGES : isDevelop() ? "http://localhost:3003/images/blog-images/" : "https://www.locktherapie.de/images/blog-images/",
    PUBLIC_URL: isDevelop() ? "http://localhost:3003/" : "https://www.locktherapie.de/",
    PUBLIC_DATA_LINK: isDevelop() ? "http://localhost:8080/data-privacy" : "https://www.locktherapie.de/data-privacy",
    LINK_LOCK_DAY_SPA: "http://lockdayspa.de/",
    STORAGE_KEY: "4WfG5jv3",
    USER_STORAGE_KEY: "cjhSopcv0w9",
    FAQ_CATEGORIES: ["News"]
}