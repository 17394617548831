import React from "react";
import {inject, observer} from "mobx-react";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import config from "../../config/main.config";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal"
import renderHTML from "react-render-html";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";


if (typeof document !== "undefined") {
    require("pure-react-carousel/dist/react-carousel.es.css");
}

@inject("stores")
@withTranslation()
@observer
export default class NewsCarousel extends React.Component {

    @observable
    visibleSlides = 3;

    constructor(props) {
        super(props);
        this.state = { modalOpen : false, modalText : '', selectedArticle : { header: '', text: ''}}
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
        let { blogStore } = this.props.stores;
        if (blogStore.article.length === 0) {
            blogStore.getAllArticles();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let width = window.innerWidth;
        //console.log("dimension " + width);
        if (width > 1600) {
            this.visibleSlides = 3;
        } else if (width > 1000) {
            this.visibleSlides = 2;
        } else  {
            this.visibleSlides = 1;
        }
    };

    cardClicked(article) {
        this.setState({ modalOpen : true, selectedArticle: article})
    }

    setOpen(isOpen) {
        this.setState({ modalOpen : isOpen})
    }

    render() {
        const {i18n} = this.props;
        let { blogStore } = this.props.stores;
        let articleCards = [];
       // console.log(this.visibleSlides);

        if (blogStore.article.length > 0) {
            let  article  = blogStore.article.filter(art => art.categories.findIndex(category => category.name === 'News') !== -1); // blogStore.categories;
            articleCards = article.map((art, index) =>
                <Slide
                    className={"outer-slide-padding"}
                    innerClassName={"inner-slide-padding"}
                    style={{padding: "50px"}}
                    index={index}
                    key={index}>
                    <Card
                        fluid
                        stretched
                        className={"blog-card"}
                        onClick={this.cardClicked.bind(this, art)}>
                        <div className={"blog-image-container"}>
                            <LazyLoadImage
                                height={"auto"}
                                width={"100%"}
                                alt={"Produkt Bild zu " + art.header}
                                effect="opacity"
                                src={config.BASE_URL_BLOG_IMAGES + art.thumbnail}/>
                        </div>
                        <Card.Content>
                            <Card.Header>
                                {art.header}
                            </Card.Header>
                            <Card.Description>
                                {art.description}
                            </Card.Description>
                        </Card.Content>
                        {/*}   <Card.Description>
                            <p style={{textAlign:"right", paddingRight: "10px" , paddingBottom: "20px"}}>Lesen Sie mehr</p>
                        </Card.Description> */}
                    </Card>
                </Slide>);
        }
        return (
            <div>
                <Modal closeIcon
                       open={ this.state.modalOpen }
                       onClose={() => this.setOpen(false)}
                       onOpen={() => this.setOpen(true)}
                       style={{ margin: "auto"}}>
                    <Modal.Header>{ this.state.selectedArticle.header }</Modal.Header>
                    <Modal.Content image scrolling>
                        <Modal.Description style={{ width : "400px" }}>
                            { renderHTML(this.state.selectedArticle.text) }
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setOpen(false)} primary>
                            { i18n.t("blog.close") }
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Grid>
                    <Grid.Row columns={1} only={"mobile"}>
                        <Grid.Column>
                            <p style={{textAlign : "center", fontSize: "16px", paddingLeft: "50px", paddingRight: "50px"}}> { i18n.t("blog.swipe") }  </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered>
                        <Grid.Column width={this.visibleSlides === 1 ? 16 : 13}>
                            <CarouselProvider
                                naturalSlideHeight={1}
                                naturalSlideWidth={0.8}
                                infinite
                                visibleSlides={this.visibleSlides}
                                totalSlides={articleCards.length}>
                                <Slider style={this.visibleSlides === 1 ? {height: "500px"} : {height: "600px"}}>
                                    { articleCards }
                                </Slider>
                                {articleCards.length > this.visibleSlides && this.visibleSlides > 1 ?
                                    <ButtonBack
                                        className={"gallery-back-button"}>
                                        <div className={"gallery-button-image"}>
                                            <Icon name={'angle left'}
                                                  className={"gallery-button-icon"}
                                                  size={"massive"}/>
                                        </div>
                                    </ButtonBack>
                                    :
                                    null}
                                {articleCards.length > this.visibleSlides && this.visibleSlides > 1 ?
                                    <ButtonNext className={"gallery-next-button"}>
                                        <div
                                            className={"gallery-button-image"}>
                                            <Icon name={'angle right'}
                                                  className={"gallery-button-icon"}
                                                  size={"massive"}/>
                                        </div>
                                    </ButtonNext>
                                    :
                                    null}
                            </CarouselProvider>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }

}

NewsCarousel.propTypes = {
    products: PropTypes.array
};

NewsCarousel.defaultProps = {
    products: []
};