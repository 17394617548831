import React from "react";
import {withTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {MAX_MOBILE_WIDTH} from "../helper/util";
import {inject} from "mobx-react";
import BreadCrumbs from "../components/BreadCrumbs";

@inject("stores")
@withTranslation()
export default class ImprintPage extends React.Component {

    componentDidMount() {
        let { bannerStore } = this.props.stores;
        bannerStore.setClaim("claim.imprint");
       // window.scrollTo(0, 0);
    }

    render() {
        const {i18n} = this.props;
        return (
            <div className={"ui grid"}>
                <Helmet>
                    <title>Thorsten Weiss | Impressum</title>
                    <meta name="description"
                          content={"Lock Therapie - Physiotherpie. Ihre Gesundheit ist bei uns in guten Händen."}/>
                    <meta name="robots" content="index,follow"/>

                    <meta property="og:site_name" content="Lock Therapie"/>
                    <meta property="og:title" content="Lock Therapie | Impressum"/>
                    <meta property="og:description"
                          content="Impressum"/>
                    <meta property="og:url" content="https://www.locktherapie.de"/>
                    <meta property="og:image:url" content="https://www.locktherapie.net/images/logo.png"/>
                    <meta property="og:type" content="website"/>
                </Helmet>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.imprint'), url: '/imprint'}]}
                />
                <div className={"row centered"}>
                    <div className={"thirteen wide computer thirteen wide mobile eight wide tablet column"}>
                        <h1 className={"subheader-text"}>Impressum</h1>
                        <p className={"blue-text"}>
                            <b>{i18n.t("footer.company")}</b>
                            <br/>
                            {i18n.t("footer.name")}
                            <br/>
                            {i18n.t("footer.street")}
                            <br/>
                            {i18n.t("footer.city")}
                            <br/>
                            Telefon:  {i18n.t("phone.number")}
                            <br/>
                            E-Mail: {i18n.t("footer.mail")} <br/>
                            <br/>
                        </p>
                        <br/>
                        <br/>
                        <p className={"blue-text"}>
                            <b>§ 1 Haftungshinweis</b>
                            <br/>
                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
                            externer
                            Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
                            verantwortlich.
                            <br/>
                            <br/>
                            <b>§ 2 Formulierungshinweis</b>
                            <br/>
                            Aus Gründen der besseren Lesbarkeit des Textes wurde, sofern es sich nicht um Zitate
                            handelt, auf
                            die Schreibweise „-er/-innen“ verzichtet. Generell wurden stattdessen die Begriffe stets
                            in
                            der
                            kürzeren, männlichen Schreibweise (z. B. Mitarbeiter) verwendet. An dieser Stelle wird
                            mit
                            Gültigkeit für die gesamte Website und alle Dokumente betont, dass dies als Synonym für
                            die
                            männliche und weibliche Form vereinfacht verwendet wurde und alle männlichen und
                            weiblichen
                            Personen
                            gleichberechtigt angesprochen werden.
                            <br/>
                            <br/>
                            <b>§ 3 Externe Links</b>
                            <br/>
                            Diese Website enthält Verknüpfungen zu Websites Dritter („externe Links“). Diese
                            Websites
                            unterliegen der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen
                            Verknüpfung
                            der externen Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
                            bestehen. Zu
                            dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei
                            Einfluss
                            auf die
                            aktuelle und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das
                            Setzen
                            von
                            externen Links bedeutet nicht, dass sich der Anbieter die hinter dem Verweis oder Link
                            liegenden
                            Inhalte zu Eigen macht. Eine ständige Kontrolle der externen Links ist für den Anbieter
                            ohne
                            konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
                            werden
                            jedoch
                            derartige externe Links unverzüglich gelöscht.
                            <br/>
                            <br/>
                            <b>§ 4 Urheber- und Leistungsschutzrechte / Copyright</b>
                            <br/>
                            Der gesamte Inhalt der Website ist urheberrechtlich geschützt. Dies betrifft alle Texte,
                            Bilder,
                            Dateien und Gestaltungselemente. Keine der auf unserer Seite veröffentlichten Inhalte
                            dürfen
                            ohne
                            ausdrückliche Genehmigung der Mobile Applikationen UG (haftungsbeschränkt) reproduziert
                            oder
                            vervielfältigt werden, sei es im Internet, als Ausdruck/Fotokopie oder auf/in anderen
                            Medien.
                            <br/>
                            Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und
                            Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht
                            zugelassene
                            Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
                            Rechteinhabers. Dies gilt insbesondere für Vervielfältigung, Bearbeitung, Übersetzung,
                            Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen
                            elektronischen
                            Medien und Systemen. Inhalte und Rechte Dritter sind dabei als solche gekennzeichnet.
                            Die
                            unerlaubte
                            Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter Seiten ist nicht
                            gestattet und
                            strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen,
                            privaten
                            und
                            nicht kommerziellen Gebrauch ist erlaubt.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}