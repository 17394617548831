import TextStore from './textStore';
import BlogStore from './blogStore';
import FaqStore from './faqStore';
import BannerStore from './bannerStore';

/**
 Inject Inital State into Stores
 For now only text store is implemented because it is the only one we need in SSR
 If you need another store implement it like text store
 */
// TODO TW: all stores except userStore
export default (stores) => ({
    textStore: new TextStore(stores),
    blogStore: new BlogStore(stores),
    faqStore: new FaqStore(stores),
    bannerStore : new BannerStore(stores)
});