import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage /*,
    getUserFromLocalStorage,
    removeTokenFromStorage,
    setTokenLocalStorage,
    setUserToLocalStorage */
} from "../helper/util";


class FaqStore {
    @observable faq = [];
    @observable loading = false;
    @observable error = false;

    @action getFaq() {
        fetch(config.BASE_URL + "faq/", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.faq = json;
                });
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action getAllFaq() {
        fetch(config.BASE_URL + "faq/all", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.faq = json;
                });
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action addFaq(newFaq) {
        this.loading = true;
        fetch(config.BASE_URL + "faq/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({faq: newFaq})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.getAllFaq();
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action editFaq(editFaq) {
        console.log("editFaq");
        console.log(editFaq);
        this.loading = true;
        fetch(config.BASE_URL + "faq/", {
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({faq: editFaq})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.getAllFaq();
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action deleteFaq(faqId) {
        this.loading = true;
        fetch(config.BASE_URL + "faq/" + faqId , {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                this.getAllFaq();
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }


}
export default FaqStore;