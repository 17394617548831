import React from "react";
import PropTypes from "prop-types";

import {withTranslation} from "react-i18next";
import sharedConfig from "../../../shared/sharedConfig";
import userStore from "../stores/userStore"
import {Helmet} from "react-helmet";
import {inject, observer} from "mobx-react";
import {observable, toJS} from "mobx";

import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Radio from "semantic-ui-react/dist/commonjs/addons/Radio";

@withTranslation()
@inject("stores")
@observer
export default class EditHeaderComponent extends React.Component {
    @observable editState = false;

    handleChange = (e, {value}) => this.setState({
        ...this.state,
        language: value,
        htmlText: '',
        description: '',
        title: ''
    });

    constructor(props) {
        super(props);

        this.changeText = this.changeText.bind(this);

        this.state = {
            htmlText: '',
            title: '',
            description: '',
            language: 'de-DE'
        }
    }

    changeText(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            ...this.state,
            [name]: value
        });
    }


    render() {
        let {id, style} = this.props;
        const {textStore} = this.props.stores;
        let language = "de-DE";

        if (this.props.i18n) {
            language = this.props.i18n.language;
        }

        const displayType = userStore.userFromServer !== null ? "admin" : "user";
        let contentToShow;

        if (displayType === "admin") {
            let textToEdit = {};

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][this.state.language] !== "undefined") {
                    textToEdit = toJS(textStore.texts[id][this.state.language]);
                } else {
                    textToEdit = {};
                }
            }

            if (this.state.htmlText !== '') {
                textToEdit.content = this.state.htmlText;
            }

            if (this.state.title !== '') {
                textToEdit.title = this.state.title;
            }

            if (this.state.description !== '') {
                textToEdit.description = this.state.description;
            }


            if (this.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "backgroundColor": "white",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";
                    customStyle.backgroundColor = "white";
                    customStyle.overflow = "auto";
                    style = customStyle;
                }

                contentToShow = <div style={style}>
                    <Form>
                        <Form.Field>
                            <label>Title Tag</label>
                            <Input value={typeof textToEdit.title === "undefined" ? "" : textToEdit.title}
                                   name='title'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Description Tag</label>
                            <Input value={typeof textToEdit.description === "undefined" ? "" : textToEdit.description}
                                   name='description'
                                   onChange={this.changeText}
                            />
                        </Form.Field>
                        {/*}    <Form.Field>
                            <label>Text</label>
                            <TextArea style={{height: "150px", width: "100%"}}
                                      name='htmlText'
                                      onChange={this.changeText}
                                      value={typeof textToEdit.content === "undefined" ? "" : textToEdit.content}/>
                        </Form.Field> */}
                        <Form.Field>
                            <Button.Group>
                                <Button color="green" onClick={() => {

                                    if (this.state.htmlText !== '') {
                                        textToEdit.content = this.state.htmlText;
                                    }

                                    if (this.state.title !== '') {
                                        textToEdit.title = this.state.title;
                                    }

                                    if (this.state.description !== '') {
                                        textToEdit.description = this.state.description;
                                    }

                                    if (typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.state.language] === "undefined") {
                                        textStore.createText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    } else {
                                        textStore.updateText(id, textToEdit, this.state.language).then(isSuccess => {
                                            if (isSuccess) {
                                                this.editState = false;
                                            }
                                        }).catch(() => {

                                        });
                                    }
                                }}>
                                    {typeof textStore.texts[id] === "undefined" || typeof textStore.texts[id][this.state.language] === "undefined" ? "Speichern" : "Bearbeiten"}
                                </Button>
                                <Button color="red" onClick={() => this.editState = false}>Abbrechen</Button>
                            </Button.Group>
                        </Form.Field>
                    </Form>

                </div>
            } else {
                const languageRadioButtons = sharedConfig.LANGUAGES.map(language => (
                    <Form.Field key={language.language}>
                        <Radio
                            label={language.label}
                            name='radioGroup'
                            value={language.language}
                            checked={this.state.language === language.language}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                ));

                contentToShow = <div style={style}>
                    <Form>
                        {languageRadioButtons}
                    </Form>
                    <h1 id={id}>{textToEdit.content}</h1>
                    <p id={"ssr" + id}>Page-title: {textToEdit.title} | Page-tags: {textToEdit.description}</p>
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.editState = true
                    }}/>
                </div>
            }

        } else {
            let textToShow = {};

            if (typeof textStore.texts[id] !== "undefined") {
                if (typeof textStore.texts[id][language] !== "undefined") {
                    textToShow = toJS(textStore.texts[id][language]);
                } else {
                    textToShow = {};
                }
            }

            contentToShow = <div>
                <Helmet>
                    <title>{textToShow.title}</title>
                    <meta name="description" content={textToShow.description}/>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
                <div style={style} id={id} dangerouslySetInnerHTML={{__html: textToShow.content}}/>
            </div>;
        }


        return (
            contentToShow
        );
    }
}

EditHeaderComponent.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object
};

EditHeaderComponent.defaultProps = {
    id: Math.random().toString(),
    style: null
};
