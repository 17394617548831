import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
//import TherapyToolsCards from "../components/TherapyToolsCards";
import userStore from "../stores/userStore"
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import BreadCrumbs from "../components/BreadCrumbs";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

@withTranslation()
@inject("stores")
@observer
export default class TherapyCenterPage extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            selectedPrice: 0,
            isComputer: true,
            isSelected0: false,
            isSelected1: false,
            isSelected2: false,
            isSelected3: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let width = window.innerWidth;
        if (width < 440) {
            this.setState({isComputer: false});
        } else {
            this.setState({isComputer: true});
        }
    }

    priceButton(id) {
        //this.setState({ open : true, selectedPrice : id})
        switch (id) {
            case 0 :
                this.setState({isSelected0: !this.state.isSelected0})
                break;
            case 1 :
                this.setState({isSelected1: !this.state.isSelected1})
                break;
            case 2 :
                this.setState({isSelected2: !this.state.isSelected2})
                break;
            case 3 :
                this.setState({isSelected3: !this.state.isSelected3})
                break;
        }
    }

    setOpen(isOpen) {

        this.setState({open: isOpen})
    }


    render() {
        const {i18n} = this.props;
        const displayType = userStore.userFromServer !== null ? "admin" : "user";

        let header = ''
        switch (this.state.selectedPrice) {
            case 0 :
                header = i18n.t("price.prescription");
                break;
            case 1 :
                header = i18n.t("price.selfpayed");
                break;
            case 2 :
                header = i18n.t("price.center");
                break;
            case 3 :
                header = i18n.t("price.alternative");
                break;
        }

        return (
            <div>
                <EditHeaderComponent id="header_price"/>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.prices'), url: '/prices'}]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column computer={16} mobile={16} tablet={16} style={{padding: "0px"}}>
                            <div className={"div-ratio-one-two-container"}>
                                <div className={"div-ratio-content"}>
                                    <Image
                                        alt={"item"}
                                        src={config.PUBLIC_URL + "images/galery_preise.jpg"}/>
                                </div>
                            </div>
                            <div className={"image_category_text_primary"}>
                                {i18n.t("titles.price")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: "50px"}} centered columns={1}>
                        <Grid.Column computer={12} tablet={12} mobile={12} style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "40px",
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderColor: "#C0C0C0",
                            margin: "auto"
                        }}>
                            <Image
                                alt={"item"}
                                className={"image_wave_icon"}
                                src={config.PUBLIC_URL + "images/welleklein.jpg"}/>
                            <EditHTMLComponent id="html_price_explanation"/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered style={{padding: "40px", backgroundColor: "#EDEDED"}}>
                        <Grid.Column computer={8} mobile={16} fluid
                                     style={{backgroundColor: "white", margin: "auto", paddingBottom: "30px"}}>
                            <div>
                                <div style={this.state.isComputer === false ? {
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    paddingTop: "30px"
                                } : {paddingLeft: "50px", paddingRight: "50px", paddingTop: "30px"}}>
                                    <Button icon labelPosition='right' fluid style={{
                                        textAlign: "left",
                                        backgroundColor: "#002873",
                                        color: "#FFFFFF",
                                        height: "70px",
                                        fontSize: "18px"
                                    }} onClick={this.priceButton.bind(this, 0)}>
                                        {this.state.isComputer ? this.state.isSelected0 === false ?
                                            <Icon name='angle right' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> :
                                            <Icon name='angle down' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> : null}
                                        {i18n.t("price.prescription")}
                                    </Button>
                                    {this.state.isSelected0 === true ? <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={10} style={{
                                                paddingTop: "20px",
                                                paddingLeft: "20px",
                                                lineHeight: "100%"
                                            }}>
                                                <EditHTMLComponent id={"price_text_0"}/>
                                            </Grid.Column>
                                            <Grid.Column width={5} style={{paddingTop: "20px"}}>
                                                <EditHTMLComponent id={"price_amounts_0"}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <p style={{fontSize: "12px"}}>
                                                    {i18n.t("price.hint")}
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> : null}
                                </div>
                                <div style={this.state.isComputer === false ? {
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    paddingTop: "30px"
                                } : {paddingLeft: "50px", paddingRight: "50px", paddingTop: "30px"}}>
                                    <Button icon labelPosition='right' fluid style={{
                                        textAlign: "left",
                                        backgroundColor: "#002873",
                                        color: "#FFFFFF",
                                        height: "70px",
                                        fontSize: "18px"
                                    }} onClick={this.priceButton.bind(this, 1)}>
                                        {this.state.isComputer ? this.state.isSelected1 === false ?
                                            <Icon name='angle right' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> :
                                            <Icon name='angle down' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> : null}
                                        {i18n.t("price.selfpayed")}
                                    </Button>
                                    {this.state.isSelected1 === true ? <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={10} style={{paddingTop: "20px", lineHeight: "100%"}}>
                                                <EditHTMLComponent id={"price_text_1"}/>
                                            </Grid.Column>
                                            <Grid.Column width={5} style={{paddingTop: "20px"}}>
                                                <EditHTMLComponent id={"price_amounts_1"}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> : null}
                                </div>
                                <div style={this.state.isComputer === false ? {
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    paddingTop: "30px"
                                } : {paddingLeft: "50px", paddingRight: "50px", paddingTop: "30px"}}>
                                    <Button icon labelPosition='right' fluid style={{
                                        textAlign: "left",
                                        backgroundColor: "#002873",
                                        color: "#FFFFFF",
                                        height: "70px",
                                        fontSize: "18px"
                                    }} onClick={this.priceButton.bind(this, 2)}>
                                        {this.state.isComputer ? this.state.isSelected2 === false ?
                                            <Icon name='angle right' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> :
                                            <Icon name='angle down' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> : null}
                                        {i18n.t("price.center")}
                                    </Button>
                                    {this.state.isSelected2 === true ? <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={10} style={{paddingTop: "20px", lineHeight: "100%"}}>
                                                <EditHTMLComponent id={"price_text_2"}/>
                                            </Grid.Column>
                                            <Grid.Column width={5} style={{paddingTop: "20px"}}>
                                                <EditHTMLComponent id={"price_amounts_2"}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> : null}
                                </div>
                                <div style={this.state.isComputer === false ? {
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    paddingTop: "30px"
                                } : {paddingLeft: "50px", paddingRight: "50px", paddingTop: "30px"}}>
                                    <Button icon labelPosition='right' fluid style={{
                                        textAlign: "left",
                                        backgroundColor: "#002873",
                                        color: "#FFFFFF",
                                        height: "70px",
                                        fontSize: "18px"
                                    }} onClick={this.priceButton.bind(this, 3)}>
                                        {this.state.isComputer ? this.state.isSelected3 === false ?
                                            <Icon name='angle right' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> :
                                            <Icon name='angle down' size={"big"}
                                                  style={{backgroundColor: "#ACB9D1"}}/> : null}
                                        {i18n.t("price.tools")}
                                    </Button>
                                    {this.state.isSelected3 === true ? <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={10} style={{paddingTop: "20px", lineHeight: "100%"}}>
                                                <EditHTMLComponent id={"price_text_3"}/>
                                            </Grid.Column>
                                            <Grid.Column width={5} style={{paddingTop: "20px"}}>
                                                <EditHTMLComponent id={"price_amounts_3"}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> : null}
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Image
                                alt={"item"}
                                style={{width: "240px", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/welle.svg"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}