export function getEditStyle(style) {
    if (style === null) {
        style = {
            "zIndex": 1,
            "position": "relative",
            "backgroundColor": "transparent",
            "overflow": "auto",
            fontSize: "20px",
            lineHeight: "24px",
            fontFamily: "sans-serif"
        };
    } else {
        // need to copy this because otherwise I get "not extendable error"
        let customStyle = Object.assign({}, style);
        customStyle.zIndex = 1;
        customStyle.position = "relative";
        customStyle.backgroundColor = "white";
        customStyle.overflow = "auto";
        customStyle.fontSize = "20px";
        customStyle.lineHeight = "24px";
        customStyle.fontFamily = "sans-serif";
        style = customStyle;
    }

    return style;
}
