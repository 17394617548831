import React from "react";
import {inject, observer} from "mobx-react";
import {withTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import BreadCrumbs from "../components/BreadCrumbs";


@withTranslation()
@inject("stores")
@observer
export default class DataPrivacyPage extends React.Component {

    componentDidMount() {
        let {bannerStore} = this.props.stores;
        bannerStore.setClaim("claim.data");
        window.scrollTo(0, 0);
    }

    render() {
        const {i18n} = this.props;
        return (
            <div className={"ui grid"}>
                <Helmet>
                    <title>Lock Therapie | Impressum</title>
                    <meta name="description"
                          content={"Lock Therapie - Physiotherpie. Ihre Gesundheit ist bei uns in guten Händen."}/>
                    <meta name="robots" content="index,follow"/>

                    <meta property="og:site_name" content="Lock Therapie"/>
                    <meta property="og:title" content="Lock Therapie | Datenschutz"/>
                    <meta property="og:description"
                          content="Impressum"/>
                    <meta property="og:url" content="https://www.locktherapie.de"/>
                    <meta property="og:image:url" content="https://www.locktherapie.net/images/logo.png"/>
                    <meta property="og:type" content="website"/>
                </Helmet>
                <div className={"row centered"}>
                    <div className={"thirteen wide computer thirteen wide mobile eight wide tablet column"}>
                        <BreadCrumbs
                            breadCrumbData={[{title: i18n.t('titles.data_privacy'), url: '/data-privacy'}]}
                        />
                        <h1 className={"subheader-text"}>Datenschutzerklärung</h1>
                        <p className={"blue-text"}>
                            Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                            EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                        <p className={"blue-text"}>
                            <b>{i18n.t("footer.company")}</b>
                            <br/>
                            {i18n.t("footer.name")}
                            <br/>
                            {i18n.t("footer.street")}
                            <br/>
                            {i18n.t("footer.city")}
                            <br/>
                            Telefon: {i18n.t("phone.number")}
                            <br/>
                            E-Mail: {i18n.t("footer.mail")}
                        </p>
                        <h2>Ihre Betroffenenrechte</h2>
                        <p className={"blue-text"}>
                            Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit
                            folgende
                            Rechte ausüben:</p>
                        <ul>
                            <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
                            <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                            <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                            <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher
                                Pflichten noch
                                nicht löschen dürfen,
                            </li>
                            <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
                            <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen
                                Vertrag
                                mit uns abgeschlossen haben.
                            </li>
                        </ul>
                        <p className={"blue-text"}>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
                            jederzeit mit Wirkung für
                            die
                            Zukunft widerrufen.</p>
                        <p className={"blue-text"}>Sie können sich jederzeit mit einer Beschwerde an die für Sie
                            zuständige Aufsichtsbehörde
                            wenden.
                            Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer
                            Arbeit
                            oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den
                            nichtöffentlichen
                            Bereich) mit Anschrift finden Sie unter: <a
                                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                                target="_blank"
                                rel="noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                        </p>
                        <h2>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h2>
                        <p className={"blue-text"}>Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
                            Datenschutzerklärung
                            genannten
                            Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten
                            Zwecken
                            findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
                        <ul>
                            <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
                            <li>die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,</li>
                            <li>die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,</li>
                        </ul>
                        <p className={"blue-text"}>die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist
                            und kein Grund zur
                            Annahme
                            besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
                            Daten
                            haben.</p>
                        <h2>Löschung bzw. Sperrung der Daten</h2>
                        <p className={"blue-text"}>Wir halten uns an die Grundsätze der Datenvermeidung und
                            Datensparsamkeit. Wir speichern Ihre
                            personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten
                            Zwecke
                            erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen
                            vorsehen.
                            Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden
                            Daten
                            routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>
                        <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
                        <p className={"blue-text"}>Wenn Sie auf unsere Website zugreifen, werden automatisch mittels
                            eines Cookies Informationen
                            allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des
                            Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers
                            und
                            ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine
                            Rückschlüsse auf
                            Ihre Person zulassen.</p>
                        <p className={"blue-text"}>Diese Informationen sind technisch notwendig, um von Ihnen
                            angeforderte Inhalte von Webseiten
                            korrekt
                            auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu
                            folgenden
                            Zwecken verarbeitet:</p>
                        <ul>
                            <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                            <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                            <li>zu weiteren administrativen Zwecken.</li>
                        </ul>
                        <p className={"blue-text"}>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem
                            berechtigten Interesse aus
                            den
                            vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf
                            Ihre
                            Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf.
                            Auftragsverarbeiter.</p>
                        <p className={"blue-text"}>Anonyme Informationen dieser Art werden von uns ggfs. statistisch
                            ausgewertet, um unseren
                            Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
                        <h2>SSL-Verschlüsselung</h2>
                        <p className={"blue-text"}>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen,
                            verwenden wir dem aktuellen
                            Stand der
                            Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
                        <h2>Kontaktformular</h2>
                        <p className={"blue-text"}>Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular
                            mit uns in Kontakt,
                            erteilen
                            Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe
                            einer
                            validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der
                            anschließenden
                            Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten
                            Angaben
                            werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
                            gespeichert. Nach
                            Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten automatisch
                            gelöscht.</p>
                        <h2><strong>Änderung unserer Datenschutzbestimmungen</strong></h2>
                        <p className={"blue-text"}>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit
                            sie stets den aktuellen
                            rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
                            Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten
                            Besuch
                            gilt dann die neue Datenschutzerklärung.</p>
                        <h2><strong>Fragen an den Datenschutzbeauftragten</strong></h2>
                        <p className={"blue-text"}>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
                            E-Mail:</p>
                        <p className={"blue-text"}>kontakt@thorstenweiss.net</p>
                        <p><em>Die Datenschutzerklärung wurde mit dem </em><a
                            href="https://www.activemind.de/datenschutz/datenschutzhinweis-generator/" target="_blank"
                            rel="noopener"><em>Datenschutzerklärungs-Generator der activeMind AG
                            erstellt</em></a><em>.</em></p>
                    </div>
                </div>
            </div>
        );
    }
}