import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import {observable} from "mobx";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal"


if (typeof document !== "undefined") {
    require("pure-react-carousel/dist/react-carousel.es.css");
}

@inject("stores")
@withTranslation()
@observer
export default class SideBarContact extends React.Component {

    @observable
    visibleSlides = 3;

    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: false,
            contactOpen: false,
            modalText: '',
            messageOpen: false,
            messageSuccess: true,
            isDataAccepted: false
        }
        this.contact = {
            firstname: '',
            surname: '',
            message: '',
            email: '',
            phone: ''
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
        let {blogStore} = this.props.stores;
        if (blogStore.article.length === 0) {
            blogStore.getAllArticles();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        let width = window.innerWidth;
        // console.log(width);
        if (width > 1930) {
            this.visibleSlides = 3;
        } else if (width > 1440) {
            this.visibleSlides = 2;
        } else {
            this.visibleSlides = 1;
        }
    };

    setPhoneOpen(isOpen) {
        this.setState({phoneOpen: isOpen})
    }

    setContactOpen(isOpen) {
        this.setState({contactOpen: isOpen})
    }

    setMessageOpen(isOpen) {
        this.setState({messageOpen: isOpen})
    }

    phoneClicked() {
        this.setState({phoneOpen: true});
    }

    contactClicked() {
        this.setState({contactOpen: true});
    }


    dataAccepted() {
        this.setState({isDataAccepted: !this.state.isDataAccepted})
    }

    sendMessage() {
        const {i18n} = this.props;
        if (this.contact.surname === '' && this.contact.firstname === '') {
            alert(i18n.t("contact.enter_name"));
            return;
        }
        if (this.contact.email === '' && this.contact.phone === '') {
            alert(i18n.t("contact.enter_contact"));
            return;
        }

        if (this.contact.message === '') {
            alert(i18n.t("contact.enter_message"));
            return;
        }

        if (this.contact.email === '' && this.contact.phone !== '') {
            this.contact.email = '-'
        }

        if (this.contact.email !== '' && this.contact.phone === '') {
            this.contact.phone = '-'
        }
        //Send to server
        let data = {
            name: this.contact.firstname + " " + this.contact.surname,
            phone: this.contact.phone,
            email: this.contact.email,
            text: this.contact.message
        };

        let self = this;

        fetch(config.BASE_URL + 'contact', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    //console.log(json)
                    if (json.hasOwnProperty("message") && json) {
                        //console.log("message there")
                        if (json.message === "success") {
                            self.setState({messageOpen: true, contactOpen: false, messageSuccess: true})
                        } else {
                            self.setState({messageOpen: true, contactOpen: false, messageSuccess: false})
                        }
                    } else {
                        self.setState({messageOpen: true, contactOpen: false, messageSuccess: false})
                    }
                });
            } else {
                self.setState({messageOpen: true, contactOpen: false, messageSuccess: false})
            }
        }).catch(() => {
            self.setState({messageOpen: true, contactOpen: false, messageSuccess: false})
        });
    }

    contactChanged(type, e) {
        if (type === "firstname") {
            this.contact.firstname = e.currentTarget.value;
        }
        if (type === "surname") {
            this.contact.surname = e.currentTarget.value;
        }
        if (type === "message") {
            this.contact.message = e.currentTarget.value;
        }
        if (type === "email") {
            this.contact.email = e.currentTarget.value;
        }
        if (type === "phone") {
            this.contact.phone = e.currentTarget.value;
        }
    }

    render() {
        const {i18n} = this.props;
        let logolink = config.BASE_URL_IMAGES + "spa_button.jpg";

        return (
            <div>
                <Modal closeIcon
                       open={this.state.phoneOpen}
                       onClose={() => this.setPhoneOpen(false)}
                       onOpen={() => this.setPhoneOpen(true)}
                       style={{margin: "auto", width: "400px"}}>
                    <Modal.Content image scrolling>
                        <Modal.Content>
                            <h3 style={{fontSize: "20px", marginBottom: "0"}}> {i18n.t("phone.phone")} </h3>
                            <hr style={{
                                width: "180px",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                borderTop: "3px solid #002873"
                            }}/>
                            <div style={{fontSize: "18px"}}>{i18n.t("phone.call_us")}</div>
                            <h3 style={{fontSize: "20px", fontWeight: "600", marginTop: "0px"}}><a
                                href={i18n.t("phone.phoneTag")}/>{i18n.t("phone.number")} </h3>
                        </Modal.Content>
                    </Modal.Content>
                </Modal>

                <Modal closeIcon
                       open={this.state.messageOpen}
                       onClose={() => this.setMessageOpen(false)}
                       onOpen={() => this.setMessageOpen(true)}
                       style={{margin: "auto", width: "400px"}}>
                    <Modal.Content image scrolling>
                        <Modal.Content>
                            <h3 style={{fontSize: "20px", marginBottom: "0"}}> {i18n.t("contact.contacting")} </h3>
                            <hr style={{
                                width: "180px",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                borderTop: "3px solid #002873"
                            }}/>
                            <h3 style={{
                                fontSize: "20px",
                                fontWeight: "600"
                            }}>{this.state.messageSuccess ? i18n.t("contact.message_sent") : i18n.t("contact.sent_error")} </h3>
                        </Modal.Content>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button style={{backgroundColor: "#BC0000", color: "#FFFFFF"}}
                                onClick={() => this.setMessageOpen(false)}>
                            {i18n.t("contact.close")}
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal closeIcon
                       open={this.state.contactOpen}
                       onClose={() => this.setContactOpen(false)}
                       onOpen={() => this.setContactOpen(true)}
                       style={{margin: "auto"}}>
                    <Modal.Content image scrolling>
                        <Modal.Content>
                            <h3 style={{fontSize: "20px", marginBottom: "0"}}> {i18n.t("contact.form")} </h3>
                            <hr style={{
                                width: "180px",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                borderTop: "3px solid #002873"
                            }}/>
                            <div style={{fontSize: "18px"}}>{i18n.t("contact.write_us")}</div>
                            <Form fluid style={{paddingTop: "20px"}}>
                                <Form.Group widths='equal'>
                                    <Form.Input label={i18n.t("contact.firstname")}
                                                placeholder={i18n.t("contact.firstname")}
                                                onChange={(e) => this.contactChanged("firstname", e)}/>
                                    <Form.Input label={i18n.t("contact.surname")}
                                                placeholder={i18n.t("contact.surname")}
                                                onChange={(e) => this.contactChanged("surname", e)}/>
                                </Form.Group>
                                <Form.TextArea label={i18n.t("contact.message")} placeholder={i18n.t("contact.message")}
                                               onChange={(e) => this.contactChanged("message", e)}/>
                                <Form.Group widths='equal'>
                                    <Form.Input label={i18n.t("contact.mail")} placeholder={i18n.t("contact.mail")}
                                                onChange={(e) => this.contactChanged("email", e)}/>
                                    <Form.Input label={i18n.t("contact.phone")} placeholder={i18n.t("contact.phone")}
                                                onChange={(e) => this.contactChanged("phone", e)}/>
                                </Form.Group>
                                <input type="checkbox" style={{marginTop: "4px", width: "18px", height: "18px"}}
                                       onChange={this.dataAccepted.bind(this)}/><label style={{paddingLeft: "10px"}}
                                                                                       htmlFor="acceptdata">Ich stimme
                                den <a href={config.PUBLIC_DATA_LINK} target="_blank"> Datenschutzbestimmungen </a> zu.</label>
                            </Form>
                        </Modal.Content>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button style={{backgroundColor: "#BC0000", color: "#FFFFFF"}}
                                onClick={() => this.setContactOpen(false)}>
                            {i18n.t("contact.cancel")}
                        </Button>
                        {this.state.isDataAccepted ?
                            <Button style={{backgroundColor: "#002873", color: "#FFFFFF"}}
                                    onClick={this.sendMessage.bind(this)}>
                                {i18n.t("contact.send")}
                            </Button>
                            :
                            <Button style={{backgroundColor: "#C0C0C0", color: "#FFFFFF"}}>
                                {i18n.t("contact.send")}
                            </Button>
                        }
                    </Modal.Actions>
                </Modal>
                <div className={"phone_button"}>
                    <Icon name={"phone"} size={"big"} style={{paddingTop: "12px", paddingLeft: "14px"}}
                          onClick={this.phoneClicked.bind(this)}/>
                </div>
                <div className={"mail_button"}>
                    <Icon name={"mail"} size={"big"} style={{paddingTop: "10px", paddingLeft: "12px"}}
                          onClick={this.contactClicked.bind(this)}/>
                </div>
                <a className={"link_button"} style={{
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "white",
                    backgroundImage: "url(" + logolink + ")"
                }} target="_blank" rel="noopener" href={config.LINK_LOCK_DAY_SPA}/>
            </div>
        );
    }

}

SideBarContact.propTypes = {};

SideBarContact.defaultProps = {};