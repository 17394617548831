import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import EditTextComponent from "../moap-components/EditTextComponent";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import BreadCrumbs from "../components/BreadCrumbs";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
//import TherapyToolsCards from "../components/TherapyToolsCards";


@withTranslation()
@inject("stores")
@observer
export default class PhilosophyPage extends React.Component {
    constructor() {
        super();
        this.state = {
            filterString: ''
        }
    }

    componentWillMount() {

    }

    filterStringChanged(e) {
        this.setState({filterString: e.target.value});
    }

    render() {
        const {i18n} = this.props;
        // <EditImageComponent id={"galery_philosophy"}/>
        return (
            <div>
                <EditHeaderComponent id="header_philosophy"/>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.philosophy'), url: '/philosophy'}]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}
                                     style={{padding: "0px"}}>

                            <div className={"div-ratio-one-two-container"}>
                                <div className={"div-ratio-content"}>
                                    <Image
                                        alt={"item"}
                                        src={config.PUBLIC_URL + "images/galery_spa_aussen.jpg"}/>
                                </div>
                            </div>
                            <div className={"image_category_text_primary"}>
                                { /* i18n.t("titles.about") */}
                                {i18n.t("titles.philosophy")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: "50px"}} centered columns={1}>
                        <Grid.Column computer={12} tablet={12} mobile={14} style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "40px",
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderColor: "#C0C0C0",
                            margin: "auto"
                        }}>
                            <Image
                                alt={"item"}
                                className={"image_wave_icon"}
                                src={config.PUBLIC_URL + "images/welleklein.jpg"}/>
                            <EditHTMLComponent id="text_philosophy_details"/>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
                {/*} <Image style={{width : "75%", paddingTop: "30px", margin: "auto"}} src={ config.PUBLIC_URL + "images/philosophie.jpg "}/>
                <Image style={{width : "170px", paddingTop: "30px", margin: "auto"}} src={ config.PUBLIC_URL + "images/welle.svg"}  /> */}
                <Grid style={{paddingTop: "50px"}}>
                    <Grid.Row columns={2} centered style={{paddingTop: "20px", backgroundColor: "#EDEDED"}}>
                        <Grid.Column computer={6} tablet={7} mobile={14}>
                            <Image
                                alt={"item"}
                                style={{width: "100%", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/philosophie.jpg "}/>
                        </Grid.Column>
                        <Grid.Column computer={6} tablet={7} mobile={14} fluid
                                     style={{height: "500px", margin: "auto"}}>
                            <div style={{position: "absolute", left: "60px", top: "40px"}}>
                                <Image
                                    alt={"item"}
                                    src={config.BASE_URL_IMAGES + "mark.png"}
                                    style={{height: "40px"}}/>
                            </div>
                            <div style={{
                                position: "absolute",
                                left: "130px",
                                top: "40px",
                                paddingRight: "80px",
                                fontSize: "18px"
                            }}>
                                <EditTextComponent id="text_philosophy"/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Image
                                alt={"item"}
                                style={{width: "240px", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/welle.svg"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}