import React from "react";
import {inject, observer} from "mobx-react";
import config from "../../config/main.config";
import {withTranslation} from "react-i18next";
import EditHTMLComponent from "../moap-components/EditHTMLComponent";
import EditHeaderComponent from "../moap-components/EditHeaderComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import BreadCrumbs from "../components/BreadCrumbs";
//import TherapyToolsCards from "../components/TherapyToolsCards";


@withTranslation()
@inject("stores")
@observer
export default class TherapyToolsPage extends React.Component {
    constructor() {
        super();
        this.state = {
            filterString: '',
            isMobile: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }

    updateDimensions = () => {
        let width = window.innerWidth;
        if (width < 430) {
            this.setState({isMobile: true})
        } else {
            this.setState({isMobile: false})
        }
    };

    filterStringChanged(e) {
        this.setState({filterString: e.target.value});
    }

    render() {
        const {i18n} = this.props;
        //  <EditImageComponent id={"galery_team"}/>
        return (
            <div>
                <EditHeaderComponent id="header_team"/>
                <BreadCrumbs
                    breadCrumbData={[{title: i18n.t('titles.team'), url: '/team'}]}
                />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}
                                     style={{padding: "0px"}}>
                            <div className={"div-ratio-one-two-container"}>
                                <div className={"div-ratio-content"}>
                                    <Image
                                        alt={"item"}
                                        src={config.PUBLIC_URL + "images/galery_spa_aussen.jpg"}/>
                                </div>
                            </div>
                            <div className={"image_category_text_primary"}>
                                {/* i18n.t("titles.about") */}
                                {i18n.t("titles.team")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Image
                    alt={"item"}
                    style={{width: "75%", paddingTop: "30px", margin: "auto"}}
                    src={config.PUBLIC_URL + "images/BerndAndreas_web.jpg "}/>
                <Grid>
                    <Grid.Row coloums={2} centered style={{paddingTop: "20px"}}>
                        <Grid.Column computer={5} tablet={7} mobile={16}>
                            <div className={"image_hint_team"} style={{textAlign: "center"}}>
                                {i18n.t("team.bernd")}
                            </div>
                            <EditHTMLComponent id={"team_bernd"}/>
                        </Grid.Column>
                        <Grid.Column only={"tablet, computer,mobile"} width={1}>
                        </Grid.Column>
                        <Grid.Column computer={5} tablet={7} mobile={16}>
                            <div className={this.state.isMobile === true ? 'image_hint_team_mobile' : 'image_hint_team'}
                                 style={{textAlign: "center"}}>
                                {i18n.t("team.andreas")}
                            </div>
                            <EditHTMLComponent id={"team_andreas"}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Image
                                alt={"item"}
                                style={{width: "280px", paddingTop: "30px", margin: "auto"}}
                                src={config.PUBLIC_URL + "images/welle.svg"}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}