import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage /*,
    getUserFromLocalStorage,
    removeTokenFromStorage,
    setTokenLocalStorage,
    setUserToLocalStorage */
} from "../helper/util";

// import history from "../helper/browserHistory"

class BlogStore {
    @observable article = [];
    @observable categories = [];
    @observable availableImages = [];
    @observable loading = false;
    @observable error = false;

    @action getAllArticles() {
        fetch(config.BASE_URL + "blog/articles", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.article = json.articles;
                    // this.filteredArticle = json;
                });
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action getAllArticlesByAdmin() {
        fetch(config.BASE_URL + "blog/articles-admin", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.article = json.articles;
                    // this.filteredArticle = json;
                });
            } else {
                alert("Fehler im Datenaustausch. Bitte wenden Sie sich an den Admin.")
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action getArticleByBlogLink(blogLink) {
        return this.article.find(art => art.blogLink === blogLink);
    }

    @action getCategories() {
        fetch(config.BASE_URL + "blog/categories", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.categories = json.categories.map(category => {
                        category.checked = true;
                        return category;
                    });
                });
            } else {
                //TODO do something
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action addArticle(newArticle, isNewArticle) {
        let httpMethod = "PUT";
        if (isNewArticle) {
            httpMethod = "POST"
        }

        fetch(config.BASE_URL + "blog/article", {
            method: httpMethod,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: JSON.stringify({article: newArticle})
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {

                });
            } else {
                alert("Error saving article");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action deleteArticle(articleId) {
        this.loading = true;
        fetch(config.BASE_URL + "blog/article/"+ articleId, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.getAllArticlesByAdmin();
                });
            } else {
                alert("Error saving article");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action getAllImages() {
        this.loading = true;
        fetch(config.BASE_URL + "blog/images", {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            }
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.availableImages = json.files;
                });
            } else {
                alert("getting images");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    @action uploadImageForBlog(file) {
        let data = new FormData();
        data.append('image', file);
        fetch(config.BASE_URL + "blog/image", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                // "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            },
            body: data,
        }).then(response => {
            this.loading = false;
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.getAllImages();
                });
            } else {
                alert("getting images");
            }
        }).catch(
            error => {
                this.fetching = false;
                console.log(error);
                /*if (error.status === 401) {
                    history.replace("/admin-login");
                }*/
            }
        );
    }

    uploadImageCallBack(file) {
        return new Promise(
            (resolve, reject) => {
                let data = new FormData();
                data.append('image', file);
                fetch(config.BASE_URL + "blog/image-text", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        // "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + getTokenFromLocalStorage()
                    },
                    body: data
                }).then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            console.log(json);
                            resolve(json);
                        });

                    } else {
                        alert("getting images");
                    }
                }).catch(
                    error => {
                        console.log(error);
                        reject();
                        alert("Fehler beim Bild-Upload");
                    }
                );
                /*        request.post('/blog/image')
                            .send(data)
                            .then(function (res) {
                                console.log(res);
                                let response = JSON.parse(res.text);
                                resolve(response);
                            })
                            .catch(() => {
                                reject();
                            });*/

            }
        );
    }


}
export default BlogStore;