import React from 'react';
import PropTypes from 'prop-types';

export default class BreadCrumbs extends React.Component {
    render() {
        const {breadCrumbData} = this.props;

        let breadCrumbs = breadCrumbData.map((breadCrumb, index) => (
            <span
                key={index + breadCrumb.url}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
            >
        <a itemProp="item" href={breadCrumb.url} title={breadCrumb.title}>
          <span itemProp="name">
            {breadCrumb.title}
          </span>
        </a>
        <meta itemProp="position" content={index + 2}/>
                {index === breadCrumbData.length - 1 ? null : (
                    <i
                        aria-hidden="true"
                        className="chevron right icon icon"
                    />
                )}
      </span>
        ));

        return (
            <div style={{display: "none"}}>
                <p itemScope itemType="http://schema.org/BreadcrumbList">
          <span
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem">
            <a itemProp="item" href={'/'} title={'HOME'}>
              <span itemProp="name">
                Home
              </span>
              <meta itemProp="position" content="1"/>
            </a>
          </span>{' '}
                    {breadCrumbData.length === 0 ? null : (
                        <i
                            aria-hidden="true"
                            className="chevron right icon icon"
                        />
                    )}
                    {breadCrumbs}
                </p>
            </div>
        );
    }
}

BreadCrumbs.propTypes = {
    breadCrumbData: PropTypes.array
};

BreadCrumbs.defaultProps = {
    breadCrumbData: []
};
